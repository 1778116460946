<template>
  <v-navigation-drawer v-model="drawer"
                       :clipped="$vuetify.breakpoint.lgAndUp"
                       app
                       color="blue-grey darken-4"
                       width="300">
    <v-card flat
            ripple
            color="blue-grey darken-4"
            :to="{name: 'MainProfile'}"
            class="text-center">
      <v-row>
        <v-col cols="12"
               class="pa-0">
          <v-row align="center"
                 justify="center">
            <v-avatar :size="80"
                      class="mt-4 mb-3">
              <v-gravatar v-if="authUser.email"
                          :email="authUser.email"
                          :size="80"
                          round
                          default-img="mm" />
            </v-avatar>
          </v-row>
        </v-col>
        <v-col cols="12"
               class="text-center">
          <div class="text-h5">
            {{ authUser.fullname }}
          </div>
          <div class="text-subtitle-1 mb-3">
            {{ authUser.role }} {{ authUser.isAdmin ? ' - (Admin)' : '' }}
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-divider />

    <v-list color="blue-grey darken-4"
            class="pt-0">
      <template v-for="item in menuItems">
        <v-subheader v-if="item.heading"
                     :key="item.heading">
          {{ item.heading }}
        </v-subheader>
        <v-list-group v-else-if="item.children"
                      :key="item.title"
                      :prepend-icon="item.icon"
                      :value="item.expanded"
                      no-action
                      color="primary">
          <template #activator>
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </template>
          <v-list-item v-for="(child, i) in item.children"
                       :key="i"
                       :to="{name: child.name, params: child.params}"
                       :disabled="child.disabled"
                       exact>
            <v-list-item-action v-if="child.icon">
              <v-icon>{{ child.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ child.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item v-else
                     :key="item.title"
                     :to="{name: item.name, params: item.params}"
                     :disabled="item.disabled"
                     exact
                     color="primary">
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-list-item v-if="isAdmin"
                   @click="$router.push({name: 'Logout'})">
        <v-list-item-action>
          <v-icon>mdi-power</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Logout
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <template #append>
      <div v-if="isAdmin"
           class="pa-2 text-center">
        Version: {{ version }}
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    menuItems: {
      type: Array,
      default: () => [],
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    drawer: {
      get() {
        return this.$store.state.session.sidebar;
      },
      set(value) {
        this.$store.commit('session/sidebar', value);
      },
    },
  },

  data() {
    return {
      version: process.env.VUE_APP_VERSION,
    };
  },
};
</script>
